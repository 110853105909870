import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import './assets/scss/index.scss';

const ChatPage = React.lazy(() => import('./pages/Chat'));
const VersionPage = React.lazy(() => import('./pages/Version'));

import './i18n/config';
import { useAuthSetting } from './hooks/useAuthSetting';
import { Loader } from './components/Loader';
import { pageRoutes } from './routes/pageRoutes';

// ---

function WrapperChat() {
  const { initialAuthSetting } = useAuthSetting();

  return (
    <>
      {initialAuthSetting ? <ChatPage /> : <Loader showTitle={false} />}
    </>
  );
}

// ---

function App() {
  return (
    <div className='content'>
      <Routes>
        <Route path={pageRoutes.chat} element={<React.Suspense fallback={null}>
          <WrapperChat />
        </React.Suspense>} />
        <Route path={pageRoutes.version} element={<React.Suspense fallback={null}>
          <VersionPage />
        </React.Suspense>} />
        <Route path='*' element={<Navigate replace to={pageRoutes.notFound} />} />
      </Routes>
    </div>
  );
}

export default App;
