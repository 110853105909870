import React from 'react';
import { useLogin } from '../api/authApi';
import { EEventName, removeEvent, subscribeEvent } from '../events/events';
import { useActions } from '../hooks/useActions';
import { setGreeting, setInitialFetching, setIsLogin, setSessionId, setIsAuthSetting, resetAuthStore } from '../redux/reducers/authSlice';
import { store } from '../redux/store';
import { ILoginResponseDTO } from '../submodules/common/interfaces/dto/auth/ilogin-response.interfaces';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { queryClient } from '..';
import { EQueryKey } from '../api/types/queryTypes';
import { useAuth } from '../hooks/useTypeSelectorHooks/useAuth';
import { setSessionStorageService } from './localStorageService';


export const onSuccess = ({ data }: IApiResponseGenericDTO<ILoginResponseDTO>) => {
  const dispatch = store.dispatch;

  dispatch(setSessionId(data.sessionId));
  dispatch(setGreeting(data.greeting));
  dispatch(setIsLogin(true));
  dispatch(setInitialFetching(true));

  dispatch(setIsAuthSetting(true));

  setSessionStorageService(data.access.accessToken, data.access.refreshToken);
};

export const onError = ({ data }: IApiResponseGenericDTO<ILoginResponseDTO>) => {
  const { setInitialFetching } = useActions();
  console.info(data);
  setInitialFetching(true);
};

const localStorageService = async () => {
  const { dispatch } = store;

  dispatch(resetAuthStore());
  await queryClient.refetchQueries([EQueryKey.AUTH]);
};

export const useAuthServiceSetting = () => {
  const { isAuthSetting } = useAuth();
  useLogin({ onSuccess, onError, enabled: !isAuthSetting });

  React.useEffect(() => {
    subscribeEvent(EEventName.STORAGE, localStorageService);
    return () => {
      removeEvent(EEventName.STORAGE, localStorageService);
    };
  }, []);
};
