import { resetAuthStore } from '../../redux/reducers/authSlice';
import { store } from '../../redux/store';
import { apiRoutes } from '../../routes/apiRoutes';
import { getAccessTokenSessionStorage, getRefreshTokenSessionStorage, removeSessionStorageService, setSessionStorageService } from '../../services/localStorageService';
import { isValueIncludes } from '../../utils/arrayUtil';
import { EMethod, EStatusCode, IRequestConfig } from '../types/apiTypes';

const refreshToken = async (): Promise<Response> => {
  return await fetch(apiRoutes.refreshToken, {
    method: EMethod.PUT,
    headers: getHeaders(getHeadersAuthBeanParams()),
    body: JSON.stringify({ token: getRefreshTokenSessionStorage() }),
  });
};

export const request = async <R, B = unknown>(
  { url = '/',
    method = EMethod.GET,
    data,
    headers = () => undefined }: IRequestConfig<B>): Promise<R> => {
  return new Promise(async (resolve, reject) => {
    try {
      const dispatch = store.dispatch;
      let response: Response = await fetch(url, { method, headers: headers(), body: JSON.stringify(data) });

      if (isAccessForbidden(response.status)) {
        const resRefreshToken = await refreshToken();
        if (resRefreshToken.ok) {
          const jsonRefreshToken = await resRefreshToken.json();
          setSessionStorageService(jsonRefreshToken.data.access.accessToken, jsonRefreshToken.data.access.refreshToken);
          response = await fetch(url, { method, headers: headers(), body: JSON.stringify(data) });
          resolve(await response.json());
        } else {
          removeSessionStorageService();
          dispatch(resetAuthStore());
          reject(await resRefreshToken.json());
        }
      }
      if (!response.ok) {
        reject(await response.json());
      }
      resolve(await response.json());
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      reject(error.message);
    }
  });
};

export const pathToUrl = (path: string, params, value) => path.replace(params, value);

export const getHeaders = (options?: Record<string, string>) => (
  {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    ...options,
  }
);

export const getHeadersAuthBeanParams = () => (
  { 'Authorization': `Bearer ${getAccessTokenSessionStorage()}` }
);

export const isAccessForbidden = (statusCode: number) => {
  return isValueIncludes<number>(statusCode, [EStatusCode.UNAUTHORIZED, EStatusCode.FORBIDDEN]);
};
