import { useQuery } from '@tanstack/react-query';
import { apiRoutes } from '../routes/apiRoutes';
import { IApiResponseGenericDTO } from '../submodules/common/interfaces/dto/common/iapi-response.interface';
import { ILoginResponseDTO } from '../submodules/common/interfaces/dto/auth/ilogin-response.interfaces';
import { EQueryKey } from './types/queryTypes';
import { getHeaders, request } from './utils/apiUtils';
import { EMethod } from './types/apiTypes';

interface IUseLoginParams {
  onSuccess: (data: IApiResponseGenericDTO<ILoginResponseDTO>) => void,
  onError: (data: IApiResponseGenericDTO<ILoginResponseDTO>) => void,
  enabled?: boolean,
}

export const useLogin = ({ onSuccess, onError, enabled = true }: IUseLoginParams) => (
  useQuery<IApiResponseGenericDTO<ILoginResponseDTO>, IApiResponseGenericDTO<ILoginResponseDTO>>({
    enabled,
    queryKey: [EQueryKey.AUTH],
    queryFn: async () => await request({ url: apiRoutes.login, method: EMethod.POST, headers: () => getHeaders() }),
    onSuccess,
    onError,
  }));

export const useRefreshToken = () => (
  useQuery<IApiResponseGenericDTO<ILoginResponseDTO>, Error>({
    queryKey: [EQueryKey.REFRESH],
    queryFn:
      async () => await request({ url: apiRoutes.refreshToken, method: EMethod.POST, headers: () => getHeaders() }),
  })
);
