import { ELocalStorage, EToken } from '../types/localStorageTypes';
import { getStorage, removeStorage, setSessionStorage } from '../utils/localStorageUtil';

export const setSessionStorageService = (accessToken: string, refreshToken: string) => {
  setSessionStorage(EToken.TOKENS, {
    [ELocalStorage.AccessToken]: accessToken,
    [ELocalStorage.RefreshToken]: refreshToken,
  });
};

export const removeSessionStorageService = () => {
  removeStorage(EToken.TOKENS);
};

export const getSessionStorageService = (key: string): { [key: string]: string } | string | null => {
  try {
    return getStorage(key);
  } catch {
    removeStorage(EToken.TOKENS);
  }
  return null;
};

export const getAccessTokenSessionStorage = (): string => {
  return getSessionStorageService(EToken.TOKENS)?.[ELocalStorage.AccessToken] ?? '';
};

export const getRefreshTokenSessionStorage = (): string => {
  return getSessionStorageService(EToken.TOKENS)?.[ELocalStorage.RefreshToken] ?? '';
};
