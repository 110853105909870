export enum EEventName {
  STORAGE = 'storage',
}

export const subscribeEvent = (eventName: string, listener: (event) => void) => {
  window.addEventListener(eventName, listener);
};

export const removeEvent = (eventName: string, listener: (event) => void) => {
  window.removeEventListener(eventName, listener);
};

export const createEvent = (eventName: string, data = {}) => {
  const event = new CustomEvent(eventName, { detail: data });
  window.dispatchEvent(event);
};
