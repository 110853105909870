import { setInitialFetching, setIsLogin, setSessionId, setGreeting, setIsAuthSetting, resetAuthStore } from './reducers/authSlice';

export const rootActions = {
  setInitialFetching,
  setIsLogin,
  setSessionId,
  setGreeting,
  setIsAuthSetting,
  resetAuthStore,
};
