import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IInitialState } from '../../types/reducerTypes/authTypes';
import { defaultError } from '../../types/messagesTypes';


const initialState: IInitialState = {
  initialFetching: false,
  isAuthSetting: false,
  isLogin: false,

  sessionId: '',
  greeting: '',

  isFetching: false,
  error: defaultError,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setInitialFetching: (state, action: PayloadAction<boolean>) => {
      state.initialFetching = action.payload;
    },
    setIsAuthSetting: (state, action: PayloadAction<boolean>) => {
      state.isAuthSetting = action.payload;
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setGreeting: (state, action: PayloadAction<string>) => {
      state.greeting = action.payload;
    },
    resetAuthStore: () => initialState,
  },
});

export const {
  setInitialFetching,
  setIsLogin,
  setSessionId,
  setGreeting,
  setIsAuthSetting,
  resetAuthStore,
} = authSlice.actions;

export const authReducer = authSlice.reducer;

